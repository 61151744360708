import React from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";
import FeelingProud from "./FeelingProud";
import Programming from "./Programming";
const theme3 = {
  body: "#14213d",
  headbody: "",
  text: "#2ec4b6",
  text2: "#ffffff",
};

export default function Greeting(props) {
  const theme = props.theme;

  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1 className="greeting-text2" style={{ color: theme.text2 }}>
                {greeting.hello}
              </h1>
              <h1 className="greeting-text" style={{ color: theme.text2 }}>
                I'm{" "}
                <span style={{ color: theme.body }}> {greeting.title} </span>{" "}
                <span className="wave-emoji">👋</span>
              </h1>
              {/* <h2 className="greeting-nickname" style={{ color: theme.text2 }}>
                ( {greeting.nickname} )
              </h2> */}
              {/* <p
                className="greeting-text-p subTitle"
                style={{ color: theme.secondaryText }}
              >
                {greeting.subTitle}
              </p> */}
              <SocialMedia theme={theme} />
              <div className="portfolio-repo-btn-div">
                <Button
                  text="Contact Me"
                  href={"/contact"}
                  theme={theme}
                  className="portfolio-repo-btn"
                />
                <Button
                  text="See My Resume"
                  newTab={true}
                  href={greeting.resumeLink}
                  theme={theme3}
                  className="portfolio-repo-btn"
                />
              </div>
            </div>
          </div>
          <div className="greeting-image-div">
            {/* <img
							alt="saad sitting on table"
							src={require("../../assests/images/feelingProud.svg")}
						></img> */}
            {/* <FeelingProud theme={theme} /> */}
            <Programming theme={theme} />
          </div>
        </div>
      </div>
    </Fade>
  );
}
